




































import type { Picture } from '@/inc/types'
import { PropType, defineComponent, ref } from '@vue/composition-api'

import UiPrices, { Prices } from '@/components/ui/Prices.vue'
import UiPicture from '@/components/ui/Picture.vue'

interface ProcessStep {
  index: string
  title: string
  text: string
  picture: Picture
  prices?: Prices
}

export default defineComponent({
  name: 'ProcessStep',
  components: { UiPicture, UiPrices },
  props: {
    content: {
      type: Object as PropType<ProcessStep>,
      required: true,
    },
    current: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const rootRef = ref<HTMLElement | null>(null)
    const pictureRef = ref<HTMLElement | null>(null)
    let isScrollingDown = true
    let savedY = 0

    const onScroll = (x: number, y: number) => {
      isScrollingDown = savedY < y
      savedY = y
    }

    const onAppear = (
      isInViewport: boolean,
      _isFullyInViewport: boolean,
      position: string
    ) => {
      const acceptedPosition = isScrollingDown ? 'bottom' : 'top'

      if (!props.current && isInViewport && position === acceptedPosition) {
        ctx.emit('step:center')
      }
    }

    return {
      rootRef,
      pictureRef,
      onAppear,
      onScroll,
    }
  },
})
