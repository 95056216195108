



























import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'ProcessNav',
  components: {},
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
  },

  setup() {
    const { content } = useGetters(['content'])
    const rootRef = ref<HTMLElement | null>(null)
    const isSticky = ref(false)
    const navHeight = 60
    let observer: InstanceType<typeof IntersectionObserver>

    const onClick = (id: string) => {
      const el = document.getElementById(id)
      const elTop = el!.getBoundingClientRect().top + window.scrollY
      const offset = navHeight + rootRef.value!.clientHeight + 180

      window.scrollTo({
        top: elTop - offset,
        left: 0,
        behavior: 'smooth',
      })
    }

    onMounted(() => {
      if (rootRef.value!.classList.contains('not-sticky')) {
        return
      }

      observer = new IntersectionObserver(
        ([e]) => {
          const applyClass = e.intersectionRatio < 1 && window.scrollY > 0
          isSticky.value = applyClass
          document.body.classList.toggle('sticky-dropdown', applyClass)
        },
        { threshold: [1], rootMargin: `-${navHeight}px 0px` }
      )

      observer.observe(rootRef.value!)
    })

    onUnmounted(() => {
      if (observer) {
        observer.unobserve(rootRef.value!)
      }
    })

    return {
      content,
      rootRef,
      isSticky,
      onClick,
    }
  },
})
