





















import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import ProcessStep from '@/components/process/Step.vue'

export default defineComponent({
  name: 'ProcessSteps',
  components: {
    ProcessStep,
  },
  setup(_props, ctx) {
    const index = ref(0)

    const setIndex = (i: number) => {
      index.value = i
      ctx.emit('index:change', index.value)
    }

    return {
      ...useGetters(['content']),
      index,
      setIndex,
    }
  },
})
